.tags-input {
  .tags {
    display: block;
    text-align: center;
    font-weight: bold;
    width: 75%;
    font-size: 0.9rem;
    margin: auto;

    .tag {
      cursor: pointer;
      background-color: var(--accent-colour);
      padding: 2px 5px;
      border-radius: 4px;
      width: fit-content;
      margin-bottom: 5px;
      margin-right: 5px;
      display: inline-block;
      user-select: none;

      &:hover {
        background-color: var(--accent-focused);
      }

      svg {
        position: relative;
        top: 1px;
      }

      &:first-of-type {
        margin-left: auto;
      }

      &:last-of-type {
        margin-right: auto;
      }
    }
  }

  input {
    border: none;
    border-bottom: 4px solid var(--accent-colour);
    background: none;
    display: block;
    text-align: center;
    font-weight: bold;
    width: 75%;
    margin: auto;
    color: var(--text-colour);

    &:hover {
      border-bottom: 4px solid var(--accent-focused);
    }

    &:focus {
      background-color: var(--lighter-head);
      outline: none;
    }

    &:read-only {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        border-bottom: 4px solid var(--accent-colour);
      }

      &:focus {
        background: none;
        outline: none;
      }
    }

    &.invalid {
      border-bottom: 4px solid var(--error-bold) !important;
      background-color: var(--error-background);
      color: var(--error-colour);
    }
  }
}
