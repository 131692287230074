.newsletter-row {
  display: flex;
  gap: 8px;
  height: 32px;
  border-bottom: 1px solid var(--header-colour);
  border-collapse: collapse;

  &:first-of-type {
    border-top: 1px solid var(--header-colour);
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19) inset;
  }

  .nr-month {
    flex: 1;
    height: fit-content;
    margin: auto;
    padding-left: 15px;
  }

  .nr-avail {
    height: fit-content;
    margin: auto;

    &.available {
      color: var(--other-accent);
    }

    &.not-available {
      color: var(--accent-colour);
    }
  }

  .nr-action {
    min-width: 70px;
    justify-content: center;
    display: flex;

    button {
      width: 65px;
      height: fit-content;
      margin: auto;
      background-color: var(--header-colour);
      color: var(--text);
      border: 0;
      border-radius: 5px;
      padding: 2px 5px;
      cursor: pointer;

      &:hover {
        background-color: var(--lighter-head);
      }
    }
  }
}
