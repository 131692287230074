.featured-modal {
  .arrow {
    position: fixed;
    top: 50%;
    transform: translateY(-100%);

    &.arrow-left {
      left: 20px;
    }

    &.arrow-right {
      right: 20px;
    }

    svg {
      &:hover {
        color: var(--accent-colour);
        cursor: pointer;
      }
    }

    &.disabled {
      svg {
        color: var(--text-colour);
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .fm-image-info {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--lighter-head);
    padding: 15px;
    border-radius: 0 0 10px 0;
  }

  .fm-image {
    max-height: 70vh;
    max-width: 70vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
