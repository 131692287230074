.home-panels {
  height: 100%;
  .top-section {
    margin: 20pt;
  }

  .content {
    margin: 20px;

    a {
      display: block;
    }
  }
}
