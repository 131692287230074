.confirmation-modal {
  background-color: var(--lighter-head);
  border-bottom: 4px solid var(--accent-colour);
  padding: 20px;
  border-radius: 10px;

  .button-row {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;

    button {
      border: none;
      border-radius: 4px;
      margin-right: 10px;
      color: var(--text-colour);
      padding: 5px 10px;
      cursor: pointer;

      &.cancel {
        background-color: var(--header-colour);

        &:hover {
          filter: brightness(0.9);
        }
      }

      &.confirm {
        background-color: var(--accent-colour);
        color: var(--white);
        &:hover {
          background-color: var(--accent-focused);
        }
      }
    }
  }
}
