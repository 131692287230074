.home-panels {
  height: 100%;
  .top-section {
    margin: 20pt;

    button {
      background-color: var(--accent-colour);
      padding: 5pt;
      border-radius: 10px;
      border: 0;
      color: var(--white);
      cursor: pointer;
      margin-right: 10px;

      &:hover {
        background-color: var(--accent-focused);
      }
    }
  }

  .content {
    .spin {
      margin: auto;
      width: fit-content;
    }

    .panels-table {
      .table-headers {
        display: flex;
        background-color: var(--header-colour);
        font-weight: bold;
        padding: 5px;

        div {
          border: 1px solid var(--header-colour);
        }
      }

      .name {
        flex: 1;
      }

      .class-colours {
        width: 170px;
        text-align: center;
      }

      .actions {
        width: 80px;
      }
    }

    .c-section {
      margin-top: 15px;

      .c-section-body {
        .example {
          transition: all ease-in-out 0.5s;
        }

        @media (min-width: 1025px) {
          .latest-news {
            width: 100%;
            background-color: #595959;
            color: #fff;
            display: block;
            float: left;
            padding: 10pt 0;
            text-align: center;
            min-height: 200px;
          }
          .newmerch {
            display: block;
            width: 100%;
            color: #fff;
            float: left;
            padding: 10pt 0;
            text-align: center;
            background-color: #2c2c2c;
          }
          .vsocmember {
            display: block;
            width: 50%;
            color: #fff;
            float: left;
            padding: 10pt 0;
            text-align: center;
          }
          .facebook {
            display: block;
            width: 50%;
            color: #fff;
            float: left;
            padding: 10pt 0;
            text-align: center;
            background-color: #434343;
          }
          .content-max-half {
            width: 50%;
            margin: auto;
            float: none;
            display: block;
          }
        }
        @media (max-width: 1024px) {
          .newmerch {
            display: block;
            width: 100%;
            color: #fff;
            float: left;
            padding: 10pt 0;
            text-align: center;
            background-color: #2c2c2c;
          }
          .latest-news {
            width: 100%;
            background-color: #595959;
            color: #fff;
            padding: 10pt 0;
            text-align: center;
          }
          .vsocmember {
            width: 100%;
            color: #fff;
            padding: 10pt 0;
            text-align: center;
          }
          .facebook {
            width: 100%;
            color: #fff;
            padding: 10pt 0;
            text-align: center;
            background-color: #434343;
          }
        }
      }
    }
  }
}
