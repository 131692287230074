.text-area-input {
  p {
    text-align: center;

    &.smol {
      font-size: 8pt;
    }
  }

  textarea {
    display: block;
    width: 68%;
    height: 10em;
    padding: 3pt;
    box-sizing: border-box;
    border: none;
    border-bottom: 4px solid var(--accent-colour);
    background-color: var(--lighter-head);
    resize: none;
    margin: auto;
    margin-top: 5pt;
    color: var(--text-colour);

    &:hover {
      border-bottom: 4px solid var(--accent-focused);
    }

    &:focus {
      background-color: var(--lighter-head);
      outline: none;
    }

    &:read-only {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        border-bottom: 4px solid var(--accent-colour);
      }

      &:focus {
        background: none;
        outline: none;
      }
    }
  }
}
