.photos {
  height: 100%;
  .top-section {
    margin: 20pt;
  }

  .content {
    width: 95%;
    margin: auto;

    .spin {
      margin: auto;
      width: fit-content;
    }

    .featured-text {
      margin: 0;
    }

    .featured-button {
      margin-top: 10px;
    }

    button {
      background-color: var(--accent-colour);
      padding: 5pt;
      border-radius: 10px;
      border: 0;
      color: var(--white);
      cursor: pointer;

      &:hover {
        background-color: var(--accent-focused);
      }
    }

    .c-section-body {
      padding: 0;
      .item-table {
        .table-headers {
          display: flex;
          background-color: var(--lighter-head);
          font-size: 12pt;
          padding: 5px;
        }

        .name {
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
        }
        .date {
          width: 150px;
          text-align: center;
        }
        .actions {
          width: 100px;
          justify-content: space-evenly;
          display: flex;
        }
      }
    }
  }
}
