.top-bar {
  display: flex;
  justify-content: space-between;
  // border-bottom: 1px solid var(--header-colour);
  background-color: var(--lighter-head);

  .section {
    display: flex;
    justify-content: center;
    width: fit-content;
    flex-direction: row;
    height: 50px;

    &.user-section {
      flex-direction: column;
      margin-right: 10px;
    }

    &.flex {
      display: flex;
      flex-direction: row;
    }

    .logo {
      height: 45px;
      margin: auto 5px;
    }

    .logo-text {
      margin: auto 5px;
      font-size: 1.6rem;
    }
    .environment-text {
      margin: auto 5px;
      font-size: 0.8rem;
    }

    .user-beginner {
      font-size: 12px;
      margin: auto 10px 0 0;
    }
    .user-name {
      font-weight: bold;
      font-size: 1.1rem;
      margin: 0 0 auto 10px;
    }

    button {
      background: none;
      border: 0;
      color: var(--text);
      cursor: pointer;
      height: 25px;

      &:hover {
        color: var(--accent-focused);
      }
    }
  }
}
