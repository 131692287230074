.dd-main {
  position: relative;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  text-align: left;
  max-width: 100%;

  .dd-text {
    width: calc(100% - 25px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dd-current {
    position: relative;
    color: var(--text);
    border: 1px solid var(--header-colour);
    border-radius: 4px;
    padding: 10px;
    outline: none;
    height: 42px;
    background-color: var(--lighter-head);
    width: 100%;
    text-align: left;
    white-space: nowrap;

    .dd-placeholder {
      color: var(--text-color);
      filter: opacity(0.6);
    }

    svg {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .clear {
      top: 13px;
      right: 25px;
      cursor: pointer;
    }

    &:focus {
      border: 1px solid var(--accent-colour);
    }

    &:disabled {
      filter: opacity(0.7);
      cursor: not-allowed;
    }
  }

  .dd-content {
    position: absolute;
    width: calc(100% - 2px);
    border-right: 1px solid var(--header-colour);
    border-left: 1px solid var(--header-colour);
    border-bottom: 1px solid var(--header-colour);
    border-radius: 0 0 4px 4px;
    background-color: var(--lighter-head);
    z-index: 10000;

    .dd-options {
      max-width: 100%;
      max-height: 210px;
      overflow-y: auto;
      overflow-x: hidden;

      .dd-button {
        display: flex;
        text-align: left;
        width: 100%;
        align-items: center;
        padding: 0 0 0 10px;
        height: 30px;
        border: none;
        outline: none;
        background-color: var(--lighter-head);
        white-space: nowrap;
        color: var(--text-colour);

        &:hover,
        &:focus {
          filter: brightness(0.9);
        }
      }
    }
  }
}
