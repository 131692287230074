.message {
  border-left: 1rem solid var(--header-colour);
  background-color: var(--lighter-head);
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem auto;
  width: 25rem;
  max-width: 90%;
  cursor: pointer;

  &.success {
    color: var(--success-colour);
    background-color: var(--success-background);
    border-color: var(--success-border);
  }

  &.warning {
    color: var(--warning-colour);
    background-color: var(--warning-background);
    border-color: var(--warning-border);
  }

  &.error {
    color: var(--error-colour);
    background-color: var(--error-background);
    border-color: var(--error-border);
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
    text-align: left !important;
  }
}
