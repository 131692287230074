.admin-edit-user {
  h2 {
    text-align: center;
  }

  .spin {
    margin: auto;
    width: fit-content;
  }
}
