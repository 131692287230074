.lb-container {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  height: 60px;
  font-size: 13px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: var(--lighter-head);
  }

  &.active {
    background-color: var(--lighter-head);
  }

  &.logout {
    background-color: var(--accent-colour);
    color: var(--white);

    &:hover {
      background-color: var(--accent-focused);
    }
  }

  &.admin {
    margin-top: auto;
    color: var(--white);
    box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f0f,
      inset -20px 0 80px #0ff, inset 20px 0 300px #f0f, inset -20px 0 300px #0ff;
    // 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;

    &:hover {
      box-shadow: inset 0 0 50px rgb(197, 197, 197),
        inset 20px 0 80px rgb(206, 5, 206), inset -20px 0 80px rgb(5, 236, 236),
        inset 20px 0 300px rgb(206, 5, 206),
        inset -20px 0 300px rgb(5, 236, 236);
    }
  }

  .lb-icon {
    width: 50px;
    min-width: 50px;
  }

  .lb-text {
    text-align: left;
    width: 130px;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
  }
}
