.left-bar {
  height: 100%;
  border-right: 1px solid var(--header-colour);

  &.shrink {
    max-width: 50px;
  }

  .version {
    position: fixed;
    left: 5px;
    bottom: 0;
  }
}

.left-bar-transition-enter {
  max-width: 170px;
  padding: 0;
}

.left-bar-transition-enter-active {
  max-width: 55px;
  transition: max-width 300ms ease-in-out;
}

.left-bar-transition-enter-done {
  max-width: 50px;
}

.left-bar-transition-exit {
  max-width: 55px;
}

.left-bar-transition-exit-active {
  max-width: 245px;
  transition: max-width 300ms ease-in-out, padding 300ms ease-in-out;
}

.left-bar-transition-exit-done {
  max-width: 170px;
}
