:root,
[data-theme="dark"] {
  --background-colour: #303036;
  --header-colour: #050401;
  --lighter-head: #272626;
  --other-accent: #30bced;
  --other-accent-focused: #18b6f0;
  --text-colour: #fffaff;
  --accent-colour: #fc5130;
  --accent-focused: #fb4522;
  --accent-dark: #df4425;
  --accent-super-dark: #912712;
  --success-colour: rgb(204, 232, 205);
  --success-background: rgb(12, 19, 13);
  --success-border: #155724;
  --warning-colour: rgb(255, 226, 183);
  --warning-background: rgb(121, 78, 9);
  --warning-border: rgb(95, 59, 0);
  --error-colour: rgb(244, 199, 199);
  --error-background: rgb(77, 8, 8);
  --error-border: rgb(58, 8, 8);
  --error-bold: rgb(255, 49, 49);
  --white: #fffaff;
  --sparkle: url(https://resources.enjin.com/202204301229/themes/core/images/tag_fx/sparkle_white.gif);
}

[data-theme="light"] {
  --background-colour: #e4e4e4;
  --header-colour: #cecece;
  --lighter-head: #dcdcdc;
  --other-accent: #30bced;
  --other-accent-focused: #18b6f0;
  --text-colour: #070707;
  --accent-colour: #fc5130;
  --accent-focused: #fb4522;
  --accent-dark: #df4425;
  --accent-super-dark: #912712;
  --success-colour: rgb(204, 232, 205);
  --success-background: rgb(12, 19, 13);
  --success-border: #155724;
  --warning-colour: rgb(255, 226, 183);
  --warning-background: rgb(121, 78, 9);
  --warning-border: rgb(95, 59, 0);
  --error-colour: rgb(244, 199, 199);
  --error-background: rgb(77, 8, 8);
  --error-border: rgb(58, 8, 8);
  --error-bold: rgb(255, 49, 49);
  --white: #fffaff;
  --sparkle: url(https://resources.enjin.com/202204301229/themes/core/images/tag_fx/sparkle_purple.gif);
}
