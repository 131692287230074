.newsletter-new-year {
  h2 {
    text-align: center;
  }

  form {
    width: 50%;
    display: block;
    margin: auto;

    input[type="button"],
    input[type="reset"],
    button[type="submit"],
    input[type="submit"] {
      display: block;
      background-color: var(--accent-colour);
      border: none;
      color: var(--white);
      text-decoration: none;
      font-weight: bold;
      margin: auto;
      cursor: pointer;
      margin-top: 10pt;
      min-width: 90px;
      height: 40px;

      &:hover {
        background-color: var(--accent-focused);
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}
