.home-panels {
  height: 100%;
  .top-section {
    margin: 20pt;
  }

  .content {
    p {
      text-align: center;
    }
  }
}
