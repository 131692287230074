html,
body {
  margin: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
}

html {
  overflow: hidden;
}

body {
  background-color: var(--background-colour);
  margin: 0;
  font-family: Georgia, serif;
  color: var(--text-colour);
}

.tox-tinymce {
  width: 60%;
  margin: 10px auto;
}

.tox-statusbar {
  display: none !important;
}

a {
  color: var(--text-colour);
  text-decoration: none;
}

a:hover {
  color: var(--accent-colour);
}

/*Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: var(--header-colour);
}
::-webkit-scrollbar-thumb {
  background: var(--accent-colour);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--accent-dark);
}
::-webkit-scrollbar-thumb:active {
  background: var(--accent-super-dark);
}
/* Firefox scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--accent-colour) var(--header-colour);
}
