.c-section {
  margin: 0 0 8px 0;

  .c-section-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    padding: 10px 20px;
    border: 1px solid var(--header-colour);
    border-radius: 2px 2px 0 0;
    background: var(--header-colour);
    color: var(--text-colour);
    user-select: none;
    overflow: hidden;
    font-weight: bold;
    cursor: pointer;

    .checkbox {
      .checkbox-text {
        color: var(--black);
        font-weight: 400;
      }
    }
    .c-section-title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      svg {
        padding-right: 6px;
      }
      .c-section-title-split {
        font-weight: 700;
      }
    }
  }

  .c-section-body {
    flex-direction: column !important;
    padding: 0;
    border-radius: 0 0 2px 2px;
    border-left: 1px solid var(--header-colour);
    border-right: 1px solid var(--header-colour);
    border-bottom: 1px solid var(--header-colour);
    overflow-y: auto;
    max-height: 0;
  }

  .c-section-transition-enter {
    max-height: 0;
    padding: 0 20px;
  }

  .c-section-transition-enter-active {
    transition: max-height 200ms ease, padding 200ms ease;
    max-height: 500px;
    padding: 20px;
  }

  .c-section-transition-enter-done {
    max-height: fit-content;
    padding: 20px;
  }

  .c-section-transition-exit {
    max-height: 500px;
    padding: 20px;
  }

  .c-section-transition-exit-active {
    transition: max-height 200ms, padding 200ms ease;
    max-height: 0;
    padding: 0 20px;
  }

  .c-section-transition-exit-done {
    max-height: 0;
    padding: 0 20px;
  }
}
