.user {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
  border-radius: 5px;
  float: left;
  margin: 10px 25px;
  padding: 10px 16px;

  .user-content {
    margin-bottom: 10px;
    .user-username {
      width: fit-content;
      &.sparkle {
        background-image: var(--sparkle);
      }
    }
  }

  .user-actions {
    display: flex;
    gap: 2px;

    div {
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      justify-content: center;

      svg {
        margin: auto;

        &.destructive {
          color: var(--error-bold);
        }
      }

      &:hover {
        background-color: var(--header-colour);
      }
    }
  }
}
