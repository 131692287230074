.login {
  height: 100%;
  h1 {
    text-align: center;
    margin-bottom: 20pt;
  }

  .mistake-text {
    margin-top: 25pt;
    margin-bottom: 2px;
    text-align: center;
  }

  .mistake-link {
    padding: 5pt;
    background-color: var(--other-accent);
    margin: auto;
    display: block;
    height: max-content;
    text-align: center;
    width: max-content;
    color: var(--text);
    text-decoration: none;

    &:hover {
      background-color: var(--other-accent-focused);
    }
  }

  .bottom {
    bottom: 5px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10pt;
    text-align: center;
  }

  form {
    width: 50%;
    display: block;
    margin: auto;

    input[type="button"],
    input[type="reset"],
    button[type="submit"],
    input[type="submit"] {
      display: block;
      background-color: var(--accent-colour);
      border: none;
      color: var(--white);
      text-decoration: none;
      font-weight: bold;
      margin: auto;
      cursor: pointer;
      margin-top: 10pt;
      min-width: 90px;
      height: 40px;

      &:hover {
        background-color: var(--accent-focused);
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}
