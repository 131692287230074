.new-roll-honour {
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  .dd-main {
    width: 75%;
    margin: auto;
  }

  .new-title {
    display: flex;
    width: 75%;
    margin: auto;

    input {
      border: none;
      border: 1px solid var(--header-colour);
      border-top: 0px;
      background-color: var(--lighter-head);
      display: block;
      text-align: center;
      font-weight: bold;
      margin: auto;
      color: var(--text-colour);
      margin: 0;
      flex: 1;

      &:hover {
        border: 1px solid var(--accent-colour);
      }

      &:focus {
        border: 1px solid var(--accent-focused);
        outline: none;
      }
    }

    .fr-add {
      background-color: var(--success-border);
      border: 1px solid var(--success-background);
      color: var(--success-colour);
      border-radius: 4px;
      padding: 2px 10px;
      height: 40px;
      filter: brightness(1.2);

      &:hover {
        filter: brightness(1);
      }
    }
  }
}
