.shop-edit-item {
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  .featured-row {
    display: flex;
    max-width: 75%;
    margin: 10px auto;

    &.placeholder {
      display: block;
      text-align: center;
    }

    .text-input {
      flex: 1;

      input {
        width: 100%;
      }
    }

    .fr-remove {
      width: 32px;
      background-color: var(--error-bold);
      border: 1px solid var(--error-background);
      color: var(--error-colour);
      border-radius: 0 4px 4px 0;
      filter: brightness(0.9);

      &:hover {
        filter: brightness(1);
      }
    }

    .fr-add {
      background-color: var(--success-border);
      border: 1px solid var(--success-background);
      color: var(--success-colour);
      border-radius: 4px;
      padding: 2px 10px;
      height: 40px;
      filter: brightness(1.2);

      &:hover {
        filter: brightness(1);
      }
    }
  }
}
