.photos {
  height: 100%;
  .top-section {
    margin: 20pt;
  }

  .content {
    width: 95%;
    margin: auto;

    .spin {
      margin: auto;
      width: fit-content;
    }

    .featured-container {
      padding: 10px;
    }

    .featured-text {
      margin: 0;
    }

    .featured-button {
      margin-top: 10px;
    }

    button {
      background-color: var(--accent-colour);
      padding: 5pt;
      border-radius: 10px;
      border: 0;
      color: var(--white);
      cursor: pointer;

      &:hover {
        background-color: var(--accent-focused);
      }
    }
  }
}
