.roll-honour {
  height: 100%;
  .top-section {
    margin: 20pt;

    button {
      background-color: var(--accent-colour);
      padding: 5pt;
      border-radius: 10px;
      border: 0;
      color: var(--white);
      cursor: pointer;

      &:hover {
        background-color: var(--accent-focused);
      }

      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }
  }

  .content {
    width: 95%;
    margin: auto;

    .c-section-body {
      padding: 0;
    }
  }
}
