.event-poster {
  display: flex;
  border: 1px solid black;
  margin: -1px 0 0 -1px;
  padding: 5px;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19) inset;
  }

  div {
    button {
      margin: auto;
      display: block;
      height: fit-content;
      background-color: var(--header-colour);
      color: var(--text);
      border: 0;
      border-radius: 5px;
      padding: 2px 5px;
      cursor: pointer;

      &:hover {
        background-color: var(--lighter-head);
      }
    }
  }
  .name {
    padding-left: 5px;
  }

  .date {
    text-align: center;
  }
}
