.position-select {
  p {
    text-align: center;
  }

  select {
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    border-bottom: 4px solid var(--accent-colour);
    background: none;
    display: block;
    text-align: center;
    font-weight: bold;
    width: 75%;
    margin: auto;
    color: var(--text-colour);

    &:hover {
      border-bottom: 4px solid var(--accent-focused);
    }

    &:focus {
      background-color: var(--lighter-head);
      outline: none;
    }

    option {
      background-color: var(--lighter-head);
    }
  }

  .outcome-show {
    margin: 10px auto;
    width: fit-content;
    text-align: center;

    h4 {
      width: 105%;
    }

    .curr-entity {
      height: 15px;
      width: 100%;
      padding: 10px;
      background-color: var(--header-colour);
    }

    .outcome-contact {
      margin: auto;
      width: 100%;
      padding: 10px;
      background-color: var(--lighter-head);
    }
  }
}
