.newsletters {
  height: 100%;
  .top-section {
    margin: 20pt;

    button {
      background-color: var(--accent-colour);
      padding: 5pt;
      border-radius: 10px;
      border: 0;
      color: var(--white);
      cursor: pointer;

      &:hover {
        background-color: var(--accent-focused);
      }
    }
  }

  .content {
    .spin {
      margin: auto;
      width: fit-content;
    }
  }
}
