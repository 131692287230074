.contacts {
  height: 100%;
  .top-section {
    margin: 20pt;

    button {
      background-color: var(--accent-colour);
      padding: 5pt;
      border-radius: 10px;
      border: 0;
      color: var(--white);
      cursor: pointer;

      &:hover {
        background-color: var(--accent-focused);
      }
    }
  }

  .content {
    .spin {
      margin: auto;
      width: fit-content;
    }

    .contacts-table {
      .table-headers {
        display: flex;
        background-color: var(--header-colour);
        font-size: 18pt;
        padding: 5px;

        div {
          border: 1px solid var(--header-colour);
        }
      }

      .name {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
      .date {
        width: 150px;
        text-align: center;
      }
      .actions {
        width: 100px;
        justify-content: space-evenly;
        display: flex;
      }
    }
  }
}
