.text-input {
  p {
    text-align: center;
  }

  input {
    border: none;
    border-bottom: 4px solid var(--accent-colour);
    background: none;
    display: block;
    text-align: center;
    font-weight: bold;
    width: 75%;
    margin: auto;
    color: var(--text-colour);

    &:hover {
      border-bottom: 4px solid var(--accent-focused);
    }

    &:focus {
      background-color: var(--lighter-head);
      outline: none;
    }

    &:read-only {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        border-bottom: 4px solid var(--accent-colour);
      }

      &:focus {
        background: none;
        outline: none;
      }
    }
  }
}
