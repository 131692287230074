.newsletter-year {
  border-collapse: collapse;
  margin-bottom: 20px;

  .ny-year {
    font-size: 18pt;
    color: white;
    background-color: #050401;
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
  }
}
